<template>
  <page>
    <v-card light rounded="lg" class="pa-10 text-center">
      {{ getText() }}
    </v-card>
  </page>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page";

@Component({
  components: { Page }
})
class SuccessPayment extends Vue {
  getText() {
    return 'Платеж проведен успешно. Обработка платежа может составить несколько часов.';
  }
}
export default SuccessPayment;
</script>

<style scoped>

</style>